.my-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.23);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 500ms;
    pointer-events: none;
    font-family: "Gill Sans", sans-serif;
    z-index: 4;
}

.my-modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal-content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    width: 50vw;
    transform: scale(0.5);
    transition: 400ms all;
    text-align: center;
    font-size: 19px;
}

.modal-content.active {
    transform: scale(1);
}

.modal-content button {
    margin: 2px;
    cursor: pointer;
    font-size: 15px;
    border: 2px #363636 solid;
    border-radius: 5px;
}

.modal-content-profile {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(5, 5, 5, 0.85);
    width: 0;
    height: 100%;
    transition: 1000ms;
    text-align: center;
    font-size: 19px;
    overflow-y: scroll;
}

#modal-profile-image {
    width: 100%;
}

#modal-profile-info {
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: left;
}

#modal-profile-logout {
    width: 120px;
    top: 100px;
    color: #f2f5f8;
    display: flex;
    justify-content: center;
    border: #f2f5f8 3px solid;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

#modal-profile-logout:active {
    transform: scale(1.1);
}

#modal-profile-info h4 {
    font-weight: normal;
    padding-top: 5px;
    color: #f2f5f8;
    padding-left: 35px;
}

.modal-content-profile.active {
    width: 30vw;
}

.custom-balance-manipulation-form{
    margin-left: 35px;
    margin-top: 10px;
    font-weight: normal;
    color: #161817;
    width: 50%;
}

.custom-balance-manipulation-form div{
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 10px;
    background-color: #f2f5f8;
}

.custom-balance-manipulation-form div input{
    all: unset;
    width: 90%;
    padding-left: 5%;
}
.custom-balance-manipulation-form div button{
    all: unset;
    display: flex;
    justify-content: center;
    padding-right: 2%;
}


@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.5s;
}




