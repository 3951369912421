
#password-hint {
    font-family: "Gill Sans", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border: 2px solid #000101;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    align-content: center;
    cursor: revert;
}


.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    height: 150px;
    background-color: rgb(67, 72, 87);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    margin-left: 15px;
}

.tooltip:hover .tooltiptext {
    font-family: "Gill Sans", sans-serif;
    visibility: visible;
    overflow-y: scroll;
}

.tooltip:hover .tooltiptext::-webkit-scrollbar {
    width: 0;
}

#resend-email-link {
    cursor: pointer;
    padding: 0;
    margin-top: 0;
    font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

html {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #f2f5f8;
    font-family: "Times New Roman", Times, serif;
}

#game-field {
    display: flex;
    height: 100%;
}

#game-info {
    height: 100%;
    font-weight: normal;
    font-size: 19px;
    width: 70%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
}

#game-info div {
    width: inherit;
}

#game-info > div {
    padding-bottom: 20%;
}

#board-capsule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#board {
    width: 20%;
    height: 20%;
    background-color: white;
    border-collapse: collapse;
    border: 1px solid black;
}

#board td, #board th {
    border: 1px solid black;
    text-align: center;
}

.game-visit-card {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 95%;
    background-color: rgba(3, 214, 255, 0.36);
    border: 2px solid black;
    border-radius: 20px;
    margin-bottom: 2%;
    padding: 2%;
    align-items: center;
}

.game-visit-card > div {
    padding-left: 10%;
}

#xo-game-list {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: normal;
}

#xo-game-list > div:first-child {
    margin: 4%;
    width: 55%;
}

#xo-game-list-scroll {
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    height: 70%;
    margin: 20px;
}

#xo-game-list-scroll::-webkit-scrollbar {
    width: 13px;
}

#xo-game-list-scroll::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 10px;
}

#xo-game-list-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(67, 72, 87);
    border-radius: 10px;
}

#xo-game-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: center;
}

#xo-game-info > p {
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 21px;
    height: 100%;
}

#xo-game-image {
    height: 70%;
}

#header {
    z-index: 2;
    background-color: rgba(82, 204, 246, 0.31);
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
}

#content {
    width: 100%;
    height: 74%;
}

#footer {
    z-index: 1;
    background-color: rgba(82, 204, 246, 0.31);
    height: 14%;
    width: 100%;
    display: block;
    justify-items: center;
}

#footer:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
}

#footer-info {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

#footer-elem-container {
    display: flex;
    padding-top: 1.5%;
    padding-right: 20%;
    justify-content: right;
}

#footer-copyright {
    margin-top: 1%;
    color: rgba(67, 72, 87, 0.55);
    text-align: center;
    font-size: 100%;
    font-weight: normal;
    letter-spacing: 1px;
    width: 100%;
    display: inline-block;
    vertical-align: middle;

}

.footer-elem {
    margin: 1%;
    font-weight: lighter;
    font-size: 150%;
    cursor: pointer;
    text-decoration: none;
    color: rgb(67, 72, 87);
}

.footer-elem:hover {
    color: #0fcbf2;
    text-decoration: underline;
}

.footer-elem:active {
    color: rgb(67, 72, 87);
}

#welcome-page {
    display: flex;
    width: 100%;
    justify-content: left;
    height: 100%;
}

.welcome-game-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}


.welcome-image {
    height: 80%;
    width: 100%;
    padding-top: 5%;
}

#welcome-message-container {
    color: rgb(67, 72, 87);
    width: 70%;
    height: 100%;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    overflow: scroll;
    overflow-x: hidden;
}

#welcome-message-container::-webkit-scrollbar {
    width: 13px;
}

#welcome-message-container::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 10px;
}

#welcome-message-container::-webkit-scrollbar-thumb {
    background-color: rgb(67, 72, 87);
    border-radius: 10px;
}

#welcome-message {
    width: 60%;
    padding-left: 20%;
    padding-top: 27%;
}

#xo-game-list-link {
    margin-right: auto;
    margin-left: auto;
    width: 50%;
    margin-top: 2%;
    display: block;
    color: rgb(29, 179, 206);
    text-align: center;
}

.info-page {
    text-align: center;
    display: block;
    justify-items: center;
    height: 100%;
    font-family: "Gill Sans", sans-serif;
    overflow: auto;
}

.info-page div:first-child {
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 15%;
    padding-right: 15%;

}

.info-page p {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

}

.button {
    height: 7%;
    width: auto;
    background-color: rgba(255, 255, 255, 0);
    border: 5px solid rgb(67, 72, 87);
    border-radius: 10px;
    margin: 1%;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.button:hover {
    color: rgb(248, 245, 245);
    border: 5px solid rgb(241, 240, 240);
    background-color: #5b5b5b;
    box-shadow: 0 0 30px 7px rgba(79, 81, 84, 0.56);
}

.profile {
    background-image: url('https://img.icons8.com/ios/100/user-male-circle--v2.png');
    background-size: contain;
    display: block;
    height: 42px;
    width: 42px;
    border-radius: 21px;
    position: absolute;
    right: 31px;
}

.profile:hover {
    box-shadow: 0 0 30px 7px rgba(229, 215, 215, 0.76);
}


.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15%;
    align-self: center;
}

.form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-content > div {
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 300px;
    max-width: 330px;
}

.input-atom {
    width: 100%;
    padding-top: 1%;
    display: flex;
    justify-content: space-between;
}

.input-atom > div {
    display: flex;
    flex-direction: row;
}

.input-atom > input {
    height: 20px;
    border-radius: 5px;
    border: 2px rgb(67, 72, 87) solid;
}

.input-form-button {
    height: 30px;
    width: 170px;
    border: 2px deepskyblue solid;
    border-radius: 5px;
    cursor: pointer;
    font-size: 19px;
}


.slider-container {
    width: 100%;
    justify-items: center;
    overflow: hidden;
    display: flex;
    height: 100%;
}

.slide {
    flex: 0 0 100%;
    justify-items: center;
    width: 100%;
    height: 100%;
}


.slider {
    display: flex;
    height: 100%;
    width: 100%;
    transition: transform 0.5s ease-in-out;
}
.game-choice-button-container{
    display: flex;
    text-align: center;
    justify-content: center;

}

.game-choice-button{
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 31px;
    color: #424242;
}

.game-choice-button:hover{
    text-shadow: 0 0 5px #b7b6b6, 0 0 10px #b7b6b6, 0 0 15px #b7b6b6, 0 0 20px #b7b6b6, 0 0 25px #ff8a00; /* Эффект свечения */
}

#file-upload-container{
    display: flex;
    align-items: center;
    color: #ffffff;
}
#file-upload-container *{
    cursor: pointer;
}


#file-upload-container img{
    height: 25px;
    margin-right: 3%;
    margin-left: 5%;
}

#avatar-edit{
    margin-top: 3%;
}